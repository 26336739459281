import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { WEB_APP } from 'src/static/app';

const TITLE = '全て無料です';
const LINES = [
  'ウェブサイトを作成しメディアとして情報発信を行っていくためには、開発などの初期費用に加えて、サーバーのレンタル料やドメイン取得など、様々なランニングコストがかかります。',
  `${WEB_APP.name.default}を利用するにあたって、費用は一切かかりません。必要になるのはkintoneのアカウントだけです。`,
];

const Component: FC = () => (
  <section className='min-h-[80vh] flex items-center py-6 sm:py-8 lg:py-12'>
    <div className='max-w-screen-lg w-full m-auto flex gap-8 flex-col lg:flex-row justify-between'>
      <div className='flex-1 flex justify-center px-8'>
        <Image src='/img/yoga-blue.webp' width={400} height={400} alt={TITLE} />
      </div>
      <div className='flex-1 flex flex-col items-center justify-center px-8'>
        <h2 className='text-slate-800 text-2xl lg:text-3xl font-bold mb-4 md:mb-6'>{TITLE}</h2>
        {LINES.map((line, i) => (
          <p key={i} className='max-w-md text-slate-600 md:text-lg mx-auto mb-8'>
            {line}
          </p>
        ))}
        <Link href={WEB_APP.featureArticlePath}>
          <a className='text-indigo-500 self-end hover:text-indigo-600 active:text-indigo-700 font-bold transition duration-100'>
            もっと知る
          </a>
        </Link>
      </div>
    </div>
  </section>
);

export default Component;
