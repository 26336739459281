import Image from 'next/image';
import React, { FC } from 'react';
import { WEB_APP } from 'src/static/app';

const TITLE = 'kintoneの強みをより活かす';
const lines = [
  'ウェブサイトの設立、メンテナンス、SEO施策・・・ウェブメディアから集客を狙うには、多くの労力と学習コストが必要です。',
  'ただ、一般的なCMSに備わっている機能は、ほとんどkintoneにも備わっています。これを活用しないのはもったいなくありませんか？',
  `${WEB_APP.name.default}に初期設定をしておくだけで、kintoneアプリのレコードが自動的にウェブメディアとしてウェブ上に公開されます。`,
];

const Component: FC = () => (
  <section className='min-h-[80vh] flex flex-col justify-center items-center py-6 sm:py-8 lg:py-12 bg-slate-100 overflow-hidden'>
    <div className='flex justify-center'>
      <Image src='/img/cloud-yellow.webp' width={280} height={180} alt={TITLE} />
    </div>
    <div className='max-w-screen-md px-8 md:px-12 py-6 md:py-8 lg:py-12 mx-auto'>
      <h2 className='text-slate-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6'>
        {TITLE}
      </h2>
      {lines.map((line, i) => (
        <p key={i} className='max-w-screen-md text-slate-600 md:text-lg text-center mx-auto'>
          {line}
        </p>
      ))}
    </div>
  </section>
);

export default Component;
