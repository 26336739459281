import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { useAuthContext } from 'src/providers/auth';
import { WEB_APP } from 'src/static/app';

const TITLE = 'さっそく始めましょう';
const LINES = [
  `まずはご利用のkintoneでデモアプリを作成し、${WEB_APP.name.default}と連携してみましょう。`,
];

const Component: FC<{ uid?: string | null }> = ({ uid }) => (
  <section className='min-h-[80vh] flex items-center py-6 sm:py-8 lg:py-12 bg-slate-200'>
    <div className='max-w-screen-lg w-full m-auto flex gap-8 flex-col justify-between'>
      <div className='flex-1 flex justify-center px-8'>
        <Image src='/img/skateboard-blue.webp' width={400} height={400} alt={TITLE} />
      </div>
      <div className='flex-1 flex flex-col items-center justify-center px-8'>
        <h2 className='text-slate-800 text-2xl lg:text-3xl font-bold mb-4 md:mb-6'>{TITLE}</h2>
        {LINES.map((line, i) => (
          <p key={i} className='text-slate-600 mx-auto mb-8'>
            {line}
          </p>
        ))}

        <div className='flex flex-col sm:flex-row sm:justify-center lg:justify-start gap-2.5'>
          <Link href='/signup'>
            <a className='inline-block bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded outline-none transition duration-100 px-8 py-3 disabled:bg-slate-300'>
              {uid ? '管理画面へ' : '使ってみる'}
            </a>
          </Link>
          <Link href={WEB_APP.featureArticlePath}>
            <a className='inline-block bg-slate-200 hover:bg-slate-300 focus-visible:ring ring-indigo-300 text-slate-500 active:text-slate-700 text-sm md:text-base font-semibold text-center rounded outline-none transition duration-100 px-8 py-3'>
              使い方
            </a>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

const Container: FC = () => {
  const { uid } = useAuthContext();

  return <Component {...{ uid }} />;
};

export default Container;
