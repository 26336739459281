import React, { FC } from 'react';
import { DeepReadonly } from 'utility-types';
import { WEB_APP } from 'src/static/app';
import Link from 'next/link';
import Image from 'next/image';
import { useAuthContext } from 'src/providers/auth';

type ContainerProps = DeepReadonly<{}>;
type Props = ContainerProps & DeepReadonly<{ uid?: string | null }>;

const Component: FC<Props> = ({ uid }) => (
  <div className='bg-white pb-6 sm:pb-8 lg:pb-12 my-8'>
    <div className='max-w-screen-xl px-4 md:px-8 mx-auto'>
      <section className='flex flex-col lg:flex-row justify-between gap-6 sm:gap-10 md:gap-16'>
        <div className='xl:w-5/12 flex flex-col justify-center sm:text-center lg:text-left lg:py-12 xl:py-24'>
          <p className='text-indigo-500 md:text-lg xl:text-xl font-semibold mb-4 md:mb-6'>
            {WEB_APP.catchphrase}
          </p>

          <h1 className='text-slate-900 text-3xl sm:text-4xl md:text-5xl md:leading-tight font-bold mb-8 md:mb-12'>
            <div>ウェブでの情報発信も</div>
            <div>kintoneだけ</div>
          </h1>

          <p className='lg:w-4/5 text-slate-500 xl:text-lg leading-relaxed mb-8 md:mb-12'>
            kintoneと連携するだけで、アプリのレコードから自動的にウェブメディアを生成して、コンバージョンを獲得できます。
          </p>

          <div className='flex flex-col sm:flex-row sm:justify-center lg:justify-start gap-2.5'>
            <Link href='/signup'>
              <a className='inline-block bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded outline-none transition duration-100 px-8 py-3 disabled:bg-slate-300'>
                {uid ? '管理画面へ' : '使ってみる'}
              </a>
            </Link>
            <Link href={WEB_APP.featureArticlePath}>
              <a className='inline-block bg-slate-200 hover:bg-slate-300 focus-visible:ring ring-indigo-300 text-slate-500 active:text-slate-700 text-sm md:text-base font-semibold text-center rounded outline-none transition duration-100 px-8 py-3'>
                使い方
              </a>
            </Link>
          </div>
        </div>

        <div className='xl:w-5/12 h-48 lg:h-auto flex justify-center items-center overflow-hidden'>
          <Image
            src='/img/b0987.webp'
            priority
            loading='eager'
            width={500}
            height={500}
            alt='kula atf'
            className='w-full h-full object-cover object-center'
          />
        </div>
      </section>
    </div>
  </div>
);

const Container: FC<ContainerProps> = () => {
  const { uid } = useAuthContext();

  return <Component {...{ uid }} />;
};

export default Container;
