import { NextPage } from 'next';
import Hero from 'src/components/pages/home/hero';
import { NextSeo } from 'next-seo';
import { WEB_APP } from 'src/static/app';
import Head from 'next/head';

import MakeUseOfKintone from 'src/components/pages/home/make-use-of-kintone';
import UseToFree from 'src/components/pages/home/use-to-free';
import ConcentrateOnWriting from 'src/components/pages/home/concentrate-on-writing';
import Performance from 'src/components/pages/home/performance';
import Security from 'src/components/pages/home/security';
import TryItNow from 'src/components/pages/home/try-it-now';

const Component: NextPage = () => (
  <div>
    <Hero />
    <MakeUseOfKintone />
    <UseToFree />
    <ConcentrateOnWriting />
    <Performance />
    <Security />
    <TryItNow />
  </div>
);

const Container: NextPage = (props) => {
  const websiteJsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: `${WEB_APP.name.default}`,
    inLanguage: 'ja',
    url: `${WEB_APP.url}`,
    abstract: `${WEB_APP.desctiption}`,
    author: {
      '@context': 'https://schema.org/',
      '@type': 'Person',
      name: `${WEB_APP.author.name}`,
      url: `${WEB_APP.author.url}`,
      sameAs: [
        `https://twitter.com/${WEB_APP.social.twitter}`,
        `https://github.com/${WEB_APP.social.github}`,
      ],
      jobTitle: 'Developer',
    },
    publisher: {
      '@type': 'Organization',
      name: `${WEB_APP.author.name}`,
      description: `${WEB_APP.author.description}`,
      logo: {
        '@type': 'ImageObject',
        url: `${WEB_APP.url}/icon/icon.png`,
        width: 60,
        height: 60,
      },
    },
  };

  const images = [`${WEB_APP.url}/img/b0987.png`];

  return (
    <>
      <Head>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteJsonLd) }}
        />
      </Head>
      <NextSeo
        canonical={WEB_APP.url}
        title={`${WEB_APP.name.default} - ${WEB_APP.catchphrase}`}
        description={WEB_APP.desctiption}
        twitter={{
          site: `@${WEB_APP.social.twitter}`,
          cardType: 'summary_large_image',
        }}
        openGraph={{
          url: WEB_APP.url,
          type: 'website',
          title: WEB_APP.name.default,
          description: WEB_APP.desctiption,
          images: images.map((image) => ({ url: image })),
          site_name: WEB_APP.name.default,
          locale: 'ja_JP',
        }}
      />
      <Component {...props} />
    </>
  );
};

export default Container;
