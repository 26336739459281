import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { WEB_APP } from 'src/static/app';

const TITLE = 'コンテンツ作成に集中しましょう';
const LINES = [
  `メディアを公開するにあたって必要となるデザイン、アクセシビリティ、SEOは全て${WEB_APP.name.default}にまかせて、コンテンツ作成に専念できます。`,
];

const Component: FC = () => (
  <section className='min-h-[80vh] bg-slate-100 flex items-center p-6 sm:p-8 lg:p-12'>
    <div className='max-w-screen-lg w-full m-auto flex gap-8 flex-col lg:flex-row-reverse justify-between'>
      <div className='flex-1 flex justify-center px-8'>
        <Image src='/img/writing.png' width={400} height={400} alt={TITLE} />
      </div>
      <div className='flex-1 flex flex-col items-center justify-center px-4'>
        <h2 className='text-slate-800 text-2xl lg:text-3xl font-bold mb-4 md:mb-6'>{TITLE}</h2>
        {LINES.map((line, i) => (
          <p key={i} className='max-w-md text-slate-600 md:text-lg mx-auto'>
            {line}
          </p>
        ))}
        <Link href={WEB_APP.featureArticlePath}>
          <a className='text-indigo-500 self-end hover:text-indigo-600 active:text-indigo-700 font-bold transition duration-100'>
            もっと知る
          </a>
        </Link>
      </div>
    </div>
  </section>
);

export default Component;
